html, body, #root {
    height: 100%;
    width: 100%;
    margin: 0;
}

.app {
    height: 100%;
    width: 100vw;
    display: grid;
}
